<template>
  <div>
    <h1 class="display-1">Select Driver</h1>
    <v-divider></v-divider>
    <v-list>
      <v-list-item-group v-model="selectedDriver" color="primary">
        <v-list-item two-line v-for="item in drivers" :key="item.id">
          <v-list-item-content>
            <v-list-item-title class="font-weight-bold">{{
              item.fullName
            }}</v-list-item-title>
            <v-list-item-subtitle>{{ item.customId }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
export default {

  props: {
    fetchDriver: {
      type: Function,
      required: false,
    },
    van: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    drivers: [],
    selectedDriver: {},
  }),

  watch: {
    selectedDriver(val) {
      this.fetchDriver(this.drivers[val])
    }
  },

  mounted() {
    this.van.assignedDrivers.forEach(driverId => {
      this.$store.dispatch("driver/getDriverByCustomId", { customId: driverId }).then((driver) => {
        this.drivers.push(driver)
      })
    })
  },
}
</script>

<style></style>
