<template>
  <div>
    <h1 class="display-1">Select Van</h1>
    <v-divider></v-divider>
    <v-list v-if="!loading">
      <v-list-item-group v-model="selectedVan" color="primary">
        <v-list-item two-line v-for="(item, i) in vans" :key="i">
          <v-list-item-content>
            <v-list-item-title class="font-weight-bold">{{
              item.brandModel
            }}</v-list-item-title>
            <v-list-item-subtitle>{{ item.customId }}</v-list-item-subtitle>
            <v-list-item-subtitle :class="{ 'green--text' : item.vanChecksOut, 'red--text': !item.vanChecksOut }">
            {{ item.timings }}
          </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>

import * as dayjs from 'dayjs'
var advancedFormat = require('dayjs/plugin/advancedFormat')
dayjs.extend(advancedFormat)

var isBetween = require('dayjs/plugin/isBetween')
dayjs.extend(isBetween)

export default {

  props: {
    fetchVan: {
      type: Function,
      required: false,
    },
    date: {
      type: String,
      required: false
    },
  },

  data: () => ({
    vans: [],
    selectedVan: {},
    vanChecksOut: false,
    selectedDriver: null,
    loading: true
  }),

  watch: {
    selectedVan(val) {
      console.log(this.vans[val].vanChecksOut)
      if(!this.vans[val].vanChecksOut) {
        return null
      } else {
        const tripWeekDay = Number(dayjs(this.date).format('d'))
        let driverId = this.vans[val].macroPlanning[tripWeekDay].driverId
        this.$store.dispatch('driver/getDriverById', { id: driverId }).then(doc => {
          this.fetchVan(this.vans[val], doc)
        })
      }
      
    }
  },

  created() {

    // const tripWeekDay = Number(dayjs(this.tripDateTime).format('d'))

    // let vanTime = this.vanMacro.get(tripWeekDay).on

    this.$store.dispatch("van/getVans").then(async (vans) => {
      this.vans = vans;
      for(let i=0; i<this.vans.length; i++) {
        let timeData = await this.getWeekDayTiming(this.vans[i])
        this.vans[i].vanChecksOut = timeData.condition
        this.vans[i].timings = timeData.data
      }
      console.log(this.vans)
      this.loading = false
    })
  },

  methods: {
    async getWeekDayTiming(van, index) {

      let item = van.macroPlanning

      const tripWeekDay = Number(dayjs(this.date).format('d'))
      // Getting time of trip
      const triptime = dayjs(this.date).format('HH:mm')

      let vanTime = item[tripWeekDay]?.time

      if(!vanTime) {
        return Promise.resolve({ condition: false, data: 'N/A' })
      }

      // Checking if the time falls between the schedule of driver and van
      if(dayjs(`2022-04-20 ${triptime}`).isBetween(dayjs(`2022-04-20 ${vanTime.split('-')[0]}`).subtract(1, 'minute'), dayjs(`2022-04-20 ${vanTime.split('-')[1]}`), 'second')) {

        // console.log(vanTime, 'Yay')

        let conflictResult = await this.checkTripConflict(van, this.date)

        console.log(conflictResult)

        if(conflictResult) return Promise.resolve({ condition: false, data: `${vanTime} (Conflict with another trip)` })

        else return Promise.resolve({ condition: true, data: vanTime })
      } else {

        // console.log(vanTime, 'Nay')

        return Promise.resolve({ condition: false, data: vanTime })

      }
      
    },

    async checkTripConflict(van, date) {
      try {

        let result = false

        let resultArray = []

        // console.log(date)

        let tripsArray = await this.$store.dispatch("order/getOrderByStatusDateVanId", { date: date.split(' ')[0], status: 'Scheduled', vanId: van.id })

        console.log(tripsArray)

        tripsArray.forEach(item => {
          let orderTime = this.getCompleteTripTime(item)

          let unixDateTime = Number(dayjs(date).format('X'))

          console.log(orderTime, unixDateTime)

          if(unixDateTime > Number(dayjs(orderTime.split(' - ')[0]).format('X')) && unixDateTime < Number(dayjs(orderTime.split(' - ')[1]).format('X'))) resultArray.push(true)
        })

        if(resultArray.length > 0) return Promise.resolve(true)
        else return Promise.resolve(false)
        
      } catch (error) {
        console.log(error)
      }
    },

    getCompleteTripTime(tmpObject) {
      // Main trip
      let mainTripStartTime = dayjs.unix(tmpObject.pickUpDateTime).format('YYYY-MM-DD HH:mm')
      let mainTripEndTime = dayjs(mainTripStartTime).add(Number(tmpObject.estimatedTime.split(' ')[0]), 'm').format('YYYY-MM-DD HH:mm')

      // Garage to source time and rest
      let tpcDbStart = dayjs(mainTripStartTime).subtract(tmpObject.garageToSource.time + tmpObject.garageToSource.rest, 'm').format('YYYY-MM-DD HH:mm')
      let tpcDbEnd = dayjs(tpcDbStart).add(tmpObject.garageToSource.time, 'm').format('YYYY-MM-DD HH:mm')

      // After source rest
      let trPreVStart = tpcDbEnd
      let trPreVEnd = mainTripStartTime

      // Post trip Rest
      let trPostVStart = mainTripEndTime
      let trPostVEnd = dayjs(mainTripEndTime).add(tmpObject.destinationToGarage.rest, 'm').format('YYYY-MM-DD HH:mm')

      // Destination to garage
      let treVbStart = trPostVEnd
      let treVbEnd = dayjs(trPostVEnd).add(tmpObject.destinationToGarage.time, 'm').format('YYYY-MM-DD HH:mm')

      return `${tpcDbStart} - ${trPostVEnd}`
    },
  },
};
</script>

<style></style>
