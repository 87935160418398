const driverRest = [
  {
    start: 0,
    end: 50,
    minutes: 25
  },
  {
    start: 51,
    end: 90,
    minutes: 45
  },
  {
    start: 91,
    end: 130,
    minutes: 65
  },
  {
    start: 131,
    end: 170,
    minutes: 85
  },
  {
    start: 171,
    end: 210,
    minutes: 105
  },
  {
    start: 211,
    end: 250,
    minutes: 125
  },
  {
    start: 251,
    end: 290,
    minutes: 145
  },
  {
    start: 291,
    end: 330,
    minutes: 165
  },
  {
    start: 331,
    end: 370,
    minutes: 185
  },
  {
    start: 371,
    end: 410,
    minutes: 205
  },
  {
    start: 411,
    end: 450,
    minutes: 225
  },
  {
    start: 451,
    end: 490,
    minutes: 245
  },
  {
    start: 491,
    end: 530,
    minutes: 265
  },
  {
    start: 531,
    end: 570,
    minutes: 285
  },
  {
    start: 571,
    end: 610,
    minutes: 305
  },
  {
    start: 611,
    end: 650,
    minutes: 325
  },
  {
    start: 651,
    end: 690,
    minutes: 345
  },
  {
    start: 691,
    end: 730,
    minutes: 365
  },
  {
    start: 731,
    end: 770,
    minutes: 385
  },
  {
    start: 771,
    end: 5000,
    minutes: 405
  }
]

export default driverRest