<template>
  <v-card color="" elevation="0" outlined class="pa-0">
    <div class="card-header">
      <v-card-title class="d-flex justify-space-between pa-5">
        <div class="white--text headline">Ajouter Order</div>
        <v-icon color="white" class="d-none d-sm-flex" @click="toggle()"
          >mdi-close-thick</v-icon
        >
      </v-card-title>
    </div>
    <v-divider />
    <v-card-text style="height: auto" class="pa-5">
      <div v-if="gotDriver">
        <v-subheader>Van: {{ selectedVan.customId }}</v-subheader>
        <v-subheader>Driver: {{ selectedDriver.customId }}</v-subheader>
      </div>
      <div>
        <select-van :fetchVan="fetchVan" v-if="!gotVan" />
        <select-driver :fetchDriver="fetchDriver" :van="selectedVan" v-if="gotVan && !gotDriver" />
        <div v-if="gotDriver">
          <v-row no-gutters>
            <v-col cols="12" sm="5">
              <v-subheader class="pa-0 subtitle-1 font-weight-bold">
                Date</v-subheader
              >
            </v-col>
            <v-col cols="12" sm="7">
              <v-menu
                v-model="dateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date"
                    dense
                    append-icon="mdi-calendar"
                    outlined
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  color="primary"
                  v-model="date"
                  @input="dateMenu = false"
                  locale="fr"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" sm="5">
              <v-subheader class="pa-0 subtitle-1 font-weight-bold">
                Time</v-subheader
              >
            </v-col>
            <v-col cols="12" sm="7">
              <v-text-field
                dense
                outlined
                v-mask="['##:##']"
                v-model="time"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters v-for="item in formText" :key="item.id">
            <v-col cols="12" sm="5">
              <v-subheader class="pa-0 subtitle-1 font-weight-bold">{{
                item.title
              }}</v-subheader>
            </v-col>
            <v-col cols="12" sm="7">
              <v-text-field outlined dense v-model="item.value"></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" sm="5">
              <v-subheader class="pa-0 subtitle-1 font-weight-bold">{{
                'Source'
              }}</v-subheader>
            </v-col>
            <v-col cols="12" sm="7">
              <v-autocomplete
                v-model="sourceModel"
                :items="sourceSearchResults"
                :loading="sourceLoading"
                :search-input.sync="source"
                outlined
                hide-no-data
                hide-selected
                item-text="Description"
                item-value="API"
                label="Pick up"
                return-object
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="5">
              <v-subheader class="pa-0 subtitle-1 font-weight-bold">{{
                'Destination'
              }}</v-subheader>
            </v-col>
            <v-col cols="12" sm="7">
              <v-autocomplete
                v-model="destinationModel"
                :items="destinationSearchResults"
                :loading="destinationLoading"
                :search-input.sync="destination"
                outlined
                hide-no-data
                hide-selected
                item-text="Description"
                item-value="API"
                label="Destination"
                return-object
                dense
              ></v-autocomplete>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-card-text>
    <v-divider />
    <v-card-actions>
      <v-row no-gutters justify-sm="space-between">
        <v-col cols="12" sm="auto" order="2" order-sm="1">
          <v-btn
            :block="$vuetify.breakpoint.xs ? true : false"
            color="grey"
            class="mt-5 mt-sm-0"
            elevation="0"
            dark
            @click="toggle()"
            >Cancel
          </v-btn>
        </v-col>
        <v-col cols="12" sm="auto" order="1" order-sm="1">
          <v-btn
            :block="$vuetify.breakpoint.xs ? true : false"
            color="primary"
            elevation="0"
            dark
            @click="createNewOrder"
          >
            Confirm
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>

import { mask } from "vue-the-mask"
import moment from 'moment'

import driverRest from '../../utils/driverRestTime'

import SelectDriver from './SelectDriver.vue'
import SelectVan from './SelectVan.vue'

import { Loader } from "@googlemaps/js-api-loader";

const MapsLoader = new Loader({
  apiKey: "AIzaSyCNFSebRQR4JK1qvH-yRGolz07ps57BAyA",
  version: "weekly",
  libraries: ["places"]
});

export default {

  directives: { mask },

  components: { SelectVan, SelectDriver },

  props: {
    toggle: {
      type: Function,
      required: false,
    },
  },

  data: () => ({

    // Maps
    loader: MapsLoader,
    service: null,
    distanceMatrix: null,

    // Maps Form
    sourceModel: null,
    sourceSearchResults: [],
    sourceLoading: false,
    source: null,

    destinationModel: null,
    destinationSearchResults: [],
    destinationLoading: false,
    destination: null,

    tripDistance: null,
    tripTime: null,

    garageSourceDistance: null,
    garageSourceTime: null,

    selectedVan: {},
    selectedDriver: {},
    gotVan: false,
    gotDriver: false,

    driverRest: driverRest,

    // Form
    dateMenu: false,
    date: "",
    time: "",

    formText: [
      {
        id: "customerName",
        title: "Customer Name",
        value: "",
      },
      {
        id: "phoneNumber",
        title: "Customer Phone Number",
        value: "",
      },
      {
        id: "customerEmail",
        title: "Customer Email",
        value: "",
      },
      {
        id: "passengers",
        title: "Passengers",
        value: "",
      },
      {
        id: "luggages",
        title: "Luggages",
        value: "",
      },
      {
        id: "babySeats",
        title: "Baby Seats",
        value: "",
      }
    ],
  }),

  watch: {
    source(newValue) {
      if (newValue) {
        this.service.getPlacePredictions(
          {
            input: this.source,
            componentRestrictions: { country: "sn" },
            types: ["establishment"],
          },
          this.displaySourceSuggestions
        );
      }
    },

    destination(newValue) {
      if (newValue) {
        this.service.getPlacePredictions(
          {
            input: this.destination,
            componentRestrictions: { country: "sn" },
            types: ["establishment"],
          },
          this.displayDestinationSuggestions
        );
      }
    },
  },

  created() {
    this.loader.load().then(() => {
      this.service = new google.maps.places.AutocompleteService();
      this.distanceMatrix = new google.maps.DistanceMatrixService();
    })
  },


  methods: {
    async createNewOrder() {
      const parameters = await this.$store.dispatch('getParameters')
      let seat = `0.${parameters.seatPercent}`
      let luggage = `0.${parameters.luggagePercent}`
      if(Number(this.order.passengers) < 5) {
        seat = '0'
      }

      if(Number(this.order.luggages) < 7) {
        luggage = '0'
      }

      let calculateDistance = Number(this.order.estimatedDistance.split(' ')[0]) * Number(parameters.pricePerKm)
      let travelPrice = Number(parameters.basicFlatRate) + calculateDistance
      let totalPrice = travelPrice * (1 + Number(seat) + Number(luggage))

      const finalFare = Math.round((totalPrice + Number.EPSILON) * 100) / 100
      console.log('YO')
      await this.getGarageAndSourceDistance()
      await this.getDistanceAndTime()
      let timeToReachSource = Number(this.garageSourceTime.split(' ')[0])
      let restTimeAfterReachingSource = 0
      this.driverRest.forEach(val => {
        if(this.garageSourceDistance.split(' ')[0] >= val.start && this.garageSourceDistance.split(' ')[0] <= val.end) {
          restTimeAfterReachingSource = val.minutes
        }
      })
      let tripDateTime = moment(`${this.date} ${this.time}`).format('X')
      // console.log("TRIP DATE TIME ::: ", tripDateTime)
      // console.log("TIME TO REACH SROUCE ::: ", timeToReachSource)
      // console.log("REST TIME AFTER SROUCE ::: ", restTimeAfterReachingSource)
      let totalTimeBeforeTrip = timeToReachSource + restTimeAfterReachingSource
      // console.log("TOTAL TIME BEFORE TRIP ::: ", totalTimeBeforeTrip, "MINUTES")
      let ActualPickUpTime = moment(`${this.date} ${this.time}`).subtract(totalTimeBeforeTrip, 'minutes').format('X')

      const payload = {
        customId: `SN-CMP-${Math.floor(Math.random() * 1000000)}`, // SN-CMP-01
        vanRef: this.selectedVan.id,
        driverRef: this.selectedDriver.id,
        totalFair: finalFare,
        customerRef: '',
        vanCustomId: this.selectedVan.customId, // SN-VC-01
        vanBrandModel: `${this.selectedVan.brand}, ${this.selectedVan.model}`, // brand + model in a string
        driverCustomId: this.selectedDriver.customId, // SN-CH-01
        driverFullName: `${this.selectedDriver.firstName} ${this.selectedDriver.lastName}`,
        customerCustomId: `SN-CP-${Math.floor(Math.random() * 1000000)}`, // CP-01
        customerFullName: this.formText[0].value,
        customerPhoneNumber: this.formText[1].value,
        customerEmail: this.formText[2].value,
        orderDateTime: Math.round((new Date()).getTime() / 1000),
        pickUpDateTime: Math.round((new Date(`${this.date} ${this.time}`)).getTime() / 1000),
        timeToReachSource: timeToReachSource,
        restTimeAfterSource: restTimeAfterReachingSource,
        totalTimeBeforeTrip: totalTimeBeforeTrip,
        actualPickUpTime: Number(ActualPickUpTime),
        dropOffDateTime: 0,
        pickUpLocation: { title: this.source, lat: 0, lng: 0 }, // Google maps coordinates
        dropOffLocation: { title: this.destination, lat: 0, lng: 0 }, // Google maps coordinates
        estimatedTime: this.tripTime, // estimated from google maps
        estimatedDistance: this.tripDistance, // estimated from google maps
        passengers: Number(this.formText[3].value),
        luggages: Number(this.formText[4].value),
        babySeats: Number(this.formText[5].value), // 2 max
        paymentStatus: 'Pending',
        orderStatus: 'Pending',
        preTripReport: {},
        postTripReport: {},
        tripStatus: '',
        actualtripTime: '',
        actualtripDistance: '',
      }

      

      console.log(payload)


      const newOrderResponse = await this.$store.dispatch('order/createOrder', payload)

      if(newOrderResponse.code == 1) {
        this.toggle()
      } else {
        this.toggle()
      }

    },

    fetchVan(van) {
      this.selectedVan = van
      this.gotVan = true
    },

    fetchDriver(driver) {
      this.selectedDriver = driver
      this.gotDriver = true
    },

    getGarageAndSourceDistance() {
      const request = {
        origins: [this.selectedVan.garageAddress],
        destinations: [this.source],
        travelMode: ['DRIVING'],
        avoidHighways: false,
        avoidTolls: false,
      }

      return new Promise((resolve, reject) => {
        this.distanceMatrix.getDistanceMatrix(request).then((response) => {

          this.garageSourceDistance = response.rows[0].elements[0].distance.text
          this.garageSourceTime = response.rows[0].elements[0].duration.text
          resolve()
        }).catch(err => {
          reject(err)
        })
      })
    },

    getDistanceAndTime() {
      const request = {
        origins: [this.source],
        destinations: [this.destination],
        travelMode: google.maps.TravelMode.DRIVING,
        avoidHighways: false,
        avoidTolls: false,
      }

      return new Promise((resolve, reject) => {
        this.distanceMatrix.getDistanceMatrix(request).then((response) => {

          this.tripDistance = response.rows[0].elements[0].distance.text
          this.tripTime = response.rows[0].elements[0].duration.text
          resolve()
        }).catch(err => {
          reject(err)
        })
      })

      
    },

    displaySourceSuggestions(predictions, status) {
      if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
        this.sourceSearchResults = [];
        return;
      }
      this.sourceSearchResults = predictions.map(
        (prediction) => prediction.description
      );
    },
    displayDestinationSuggestions(predictions, status) {
      if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
        this.destinationSearchResults = [];
        return;
      }
      this.destinationSearchResults = predictions.map(
        (prediction) => prediction.description
      );
    },
  }

}
</script>

<style>

</style>